<template>
  <div class="follow-search-bar mx-3 mb-3">
    <form
      class="d-flex align-items-start"
      :class="{ 'fix-height': !lot, 'area-height': lot }"
      @submit.prevent="handleSearch"
    >
      <div class="d-flex align-items-center flex-1" :class="{ 'area-height': lot }">
        <div
          class="bg-primary px-2 py-1 rounded-left fix-width d-flex align-items-center"
          :class="{ 'fix-height': !lot, 'area-height': lot }"
        >
          <b-form-checkbox v-model="lot" name="is-lot-search" size="sm">
            <span class="text-white p-1 cursor-pointer">{{ $t(`form.labels.lotSearch`) }}</span>
          </b-form-checkbox>
        </div>
        <b-form-select
          v-model="searchType"
          size="sm"
          class="type-select rounded-0 fix-width"
          :disabled="loading"
          :options="options"
          :class="{ 'fix-height': !lot, 'area-height': lot }"
          required
        ></b-form-select>
        <component
          size="sm"
          class="rounded-0 search-component flex-1"
          v-model="searchText"
          :disabled="loading"
          :is="lot ? 'b-form-textarea' : 'b-form-input'"
          :class="{ 'fix-height': !lot, 'area-height pt-4': lot }"
          :placeholder="$t(`form.placeholders.${lot ? 'lotSearch' : 'search'}`)"
          no-resize
          required
        ></component>
        <b-button
          variant="primary"
          size="sm"
          class="rounded-right search-button"
          type="submit"
          :class="{ 'fix-height': !lot, 'area-height': lot }"
          :title="$t(`form.placeholders.${lot ? 'lotSearch' : 'search'}`)"
          :disabled="loading"
        >
          <uello-icon v-if="!loading" :icon="lot ? 'file-document-box-search' : 'magnify'" />
          <Loading :height="20" :width="20" v-else />
        </b-button>
      </div>

      <uello-button
        class="fix-height ml-4 fix-width"
        color="gray"
        icon="file-chart"
        iconSize="small"
        v-if="visible"
        :title="$t('form.buttons.generateReport')"
        :disabled="loading"
        @click="showReportModal"
      >
        {{ $t('form.buttons.generateReport') }}
      </uello-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import * as types from '@/store/types';
import { formatFilters } from '@/utils';
import { UelloButton, UelloIcon } from '@uello/componentello';
import Loading from '@/components/Loading.vue';

export default {
  name: 'follow-search-bar',
  props: {
    resetFilters: {
      type: Boolean,
      default: () => true,
    },
    visible: {
      type: Boolean,
      default: () => true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  components: {
    Loading,
    UelloButton,
    UelloIcon,
  },
  data() {
    return {
      lot: false,
      searchType: 'invoice_key',
      searchText: '',
      loading: false,
      options: [
        { value: 'invoice_key', text: this.$t('form.labels.nfeKey') },
        { value: 'invoice_number', text: this.$t('form.labels.nfeNumber') },
        { value: 'identifier', text: this.$t('form.labels.tag') },
        { value: 'shipping_number', text: this.$t('form.labels.shipping_number') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPage: types.GETTER_PAGE,
      getLimit: types.GETTER_LIMIT,
      getOrders: types.GETTER_ORDERS,
    }),
  },
  methods: {
    ...mapActions({
      setOrderLot: types.ACTION_ORDER_LOT_REQUEST,
    }),
    showReportModal() {
      this.$bvModal.show('modal-email-report');
    },
    async handleSearch() {
      this.loading = true;
      const label = this.options.filter(opt => opt.value === this.searchType)[0].text;

      this.setOrderLot({
        type: this.searchType,
        value: this.searchText,
        filters: formatFilters(this.filters),
      });
      this.$emit('loading', {
        label,
        value: { type: this.searchType, value: this.searchText },
        field: 'lot_search',
      });
      this.searchText = '';
    },
  },
  watch: {
    getOrders(val, oldVal) {
      if (val && val !== oldVal) {
        this.loading = false;
      }
    },
    resetFilters(val) {
      if (!val && this.searchText) {
        this.searchText = '';
      }
    },
  },
};
</script>

<style lang="scss">
.follow-search-bar {
  transition: width 200ms ease;

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--primary-darken);
    border-color: var(--primary-darken);
  }

  .area-height {
    height: 73px;
    max-height: 73px;
  }

  .type-select {
    height: 100%;
    border-left: 0;
  }

  .fix-width {
    width: 165px;
  }

  .fix-height {
    height: 32px;
  }

  .search-component {
    border-left: 0;
    border-right: 0;
  }

  .search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .report-generate {
    width: 230px;
  }
}
</style>
